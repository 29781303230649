//
// print.scss
//

// Used invoice page
@media print {
  .vertical-menu,
  .right-bar,
  .page-title-box,
  .navbar-header,
  .footer {
    display: none !important;
  }
  .card-body,
  .main-content,
  .right-bar,
  .page-content,
  body {
    padding: 0;
    margin: 0;
  }

  .card {
    border: 0;
  }

  .autoHeight {
    font-size: 12px !important;
  }
}
